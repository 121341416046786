// API URLs
export var REGISTER_API_URL = '/api/profile/register';
export var AUTHENTICATE_API_URL = '/api/profile/authenticate';
export var REQUEST_PASSWORD_RESET_API_URL = '/api/profile/requestPasswordReset';
export var RESET_PASSWORD_API_URL = '/api/profile/resetPasswordSubmit';
export var EMAIL_API_URL = '/api/profile/validateEmail';
export var FEDERATED_LOGIN_API_URL = '/api/profile/FederatedAuthorize';
export var FEDERATED_LINK_API_URL = '/api/profile/FederatedLink';
export var FEDERATED_SETTINGS_API_URL = '/api/userProfileSettings/federatedAccountLink';
export var SAVE_ANSWERS_API_URL = '/api/question/saveAnswers'; // Page URLS

export var JUST_FOR_YOU_URL = '/user-profile/just-for-you'; // Cookies

export var IS_LOGGED_IN_COOKIE_NAME = 'IsLoggedIn';
export var NOT_ELIGIBLE_COOKIE_NAME = 'NotEligible'; // Regex

export var EMAIL_REGEX = /^((\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)\s*)+$/;
export var PASSWORD_FULL_REGEX = /^(?=.*\d)(?=.*[a-zA-Z]).{8,20}$/;
export var PASSWORD_ALPHA_REGEX = /^(?=.*[a-zA-Z])\S+$/;
export var PASSWORD_NUMERIC_REGEX = /^(?=.*\d)\S+$/;
export var PASSWORD_LENGTH_REGEX = /^\S{8,20}$/;
export var ZIP_CODE_REGEX = {
  "United States": /^[0-9]{5}-?[0-9]{4}$|^[0-9]{5}$/,
  "Canada": /^[A-Z][0-9][A-Z][ ]?[0-9][A-Z][0-9]$/
}; //Federated Login

export var SOCIAL_PROVIDER_TYPE = {
  Facebook: 0,
  Google: 1
};
export var THIRD_PARTY_BLOCKED = "GMI_ThirdPartyBlocked"; // Survey

export var SURVEY_EVENTS = {
  ready: 'GMI_SurveyReady',
  show: 'GMI_ShowSurvey',
  complete: 'GMI_SurveyComplete',
  dismissed: 'GMI_SurveyDismissed'
}; //Questions

export var MULTI_CHOICE_QUESTION_TYPE = 'MultiSelect';