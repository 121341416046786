import { isIntersectionObserverSupported, getDefaultObserverTarget } from '@Shared/Common';

var componentRegistrar = function componentRegistrar(config) {
  var internal = {
    domContentLoaded: false,
    deferralExecuteActionTriggered: false,
    //true when user action triggers to execute deferred components
    componentEntries: []
  },
      //PS Customization: move these changes back into Common
  external = {
    debugMode: false,
    componentDeferralDisabled: false
  },
      defaultConfig = {
    simpleObserverOptions: {
      root: null,
      //viewport is the root
      rootMargin: "80px 0px 0px 0px",
      threshold: [0, 1]
    },
    onDemandObserverOptions: {
      root: null,
      //viewport is the root
      rootMargin: "300px 0px",
      threshold: 0.01
    },
    simpleObserverRatioTreshold: 1,
    onDemandObserverRatioThreshold: 0
  };
  internal.constants = {
    deferIdAttribute: "data-deferId",
    deferType: {
      none: "None",
      simple: "Simple",
      onDemand: "OnDemand"
    },
    status: {
      initializeComplete: "initializeComplete",
      immediateComponentsComplete: "immediateComponentsComplete",
      simpleDeferComponentsComplete: "simpleDeferComponentsComplete",
      deferralActionTriggered: "deferralActionTriggered",
      allComponentsComplete: "allComponentsComplete"
    }
  };
  config = config || {};
  internal.config = {
    testMode: !!config.testMode,
    simpleObserverOptions: config.simpleObserverOptions || defaultConfig.simpleObserverOptions,
    simpleObserverRatioTreshold: config.simpleObserverRatioTreshold || defaultConfig.simpleObserverRatioTreshold,
    onDemandObserverOptions: config.onDemandObserverOptions || defaultConfig.onDemandObserverOptions,
    onDemandObserverRatioThreshold: config.onDemandObserverRatioThreshold || defaultConfig.onDemandObserverRatioThreshold
  };

  internal.prepareComponent = function (componentEntry) {
    //set default values if defer options not defined
    if (!componentEntry.componentMetadata.deferOptions) {
      componentEntry.componentMetadata.deferOptions = {
        deferComponent: false,
        deferType: internal.constants.deferType.none,
        deferId: null
      };
    }

    internal.componentEntries.push(componentEntry);
  };

  internal.executeComponent = function (componentEntry) {
    if (componentEntry.hasExecuted === true) {
      internal.logMessage("Bypassing execution. Component already executed: " + componentEntry.componentMetadata.componentName);
      return;
    }

    var deferType = componentEntry.componentMetadata.deferOptions.deferType || "none";
    internal.logMessage("Executing component func (" + deferType + "): " + componentEntry.componentMetadata.componentName); //execute the component's function - this will kick of it's initialization logic

    try {
      componentEntry.func.apply(this, arguments);
    } catch (err) {
      internal.logMessage("Component execution failed with an error: " + err);
      componentEntry.failedWithError = true;
    }

    componentEntry.hasExecuted = true;
    internal.logExecutionStatus();
  };

  internal.executeImmediateComponents = function () {
    var currentStatus = null,
        immediateComponents = internal.getImmediateComponents();

    if (internal.isComponentDeferralEnabled()) {
      currentStatus = internal.constants.status.immediateComponentsComplete;
    } else {
      internal.logMessage("Component deferral not enabled. Executing all components...");
      currentStatus = internal.constants.status.allComponentsComplete;
    }

    internal.logMessage("Executing immediate components: " + immediateComponents.length + " of " + internal.componentEntries.length);
    immediateComponents.forEach(function (componentEntry) {
      internal.executeComponent(componentEntry);
    });
    internal.setStatus(currentStatus);
  };

  internal.executeSimpleDeferredComponents = function () {
    var simpleDeferComponents = internal.getFilteredComponents(internal.constants.deferType.simple, false);
    internal.logMessage("Executing simple deferred components: " + simpleDeferComponents.length + " of " + internal.componentEntries.length);
    simpleDeferComponents.forEach(function (componentEntry) {
      internal.executeComponent(componentEntry);
    });
    internal.setStatus(internal.constants.status.simpleDeferComponentsComplete);
  };

  internal.executeOnDemandDeferredComponent = function (deferId) {
    var onDemandDeferredEntry = internal.getOnDemandDeferredComponent(deferId);

    if (!onDemandDeferredEntry) {
      internal.logMessage("OnDemand deferred component not found. Unable to execute: " + deferId);
      return;
    }

    internal.executeComponent(onDemandDeferredEntry);
  };

  internal.getImmediateComponents = function () {
    //if deferral is disabled - execute all components immediately
    if (internal.isComponentDeferralEnabled() === false) {
      return internal.getFilteredComponents(null, false); //return all not-executed components
    } //return a list of components that are not marked for deferral


    return internal.componentEntries.filter(function (entry) {
      return entry.componentMetadata.deferOptions.deferComponent !== true && !entry.hasExecuted;
    });
  };

  internal.getFilteredComponents = function (deferType, hasExecuted) {
    var filteredComponents = internal.componentEntries;

    if (deferType !== null) {
      filteredComponents = filteredComponents.filter(function (entry) {
        return entry.componentMetadata.deferOptions.deferType === deferType;
      });
    }

    if (hasExecuted !== null) {
      filteredComponents = filteredComponents.filter(function (entry) {
        return !!entry.hasExecuted === hasExecuted;
      });
    }

    return filteredComponents;
  };

  internal.getOnDemandDeferredComponent = function (deferId) {
    if (!deferId) return null;
    var onDemandComponents = internal.getFilteredComponents(internal.constants.deferType.onDemand, false);
    var onDemandDeferredEntry = onDemandComponents.filter(function (entry) {
      return entry.componentMetadata.deferOptions.deferId === deferId;
    });

    if (onDemandDeferredEntry && onDemandDeferredEntry.length === 1 && onDemandDeferredEntry[0]) {
      return onDemandDeferredEntry[0];
    }

    return null; //return null for missing or duplicate entries
  };

  internal.isComponentDeferralEnabled = function () {
    return !external.componentDeferralDisabled && isIntersectionObserverSupported() && internal.deferralExecuteActionTriggered !== true;
  };

  internal.handleSimpleIntersectionEvent = function (intersectionEntries, observer) {
    intersectionEntries.forEach(function (entry) {
      if (entry.intersectionRatio < internal.config.simpleObserverRatioTreshold) {
        internal.deferralExecuteActionTriggered = true;
      }
    });

    if (internal.deferralExecuteActionTriggered === true) {
      observer.disconnect();
      internal.setStatus(internal.constants.status.deferralActionTriggered);
      internal.executeSimpleDeferredComponents();
    }
  };

  internal.handleOnDemandIntersectionEvent = function (intersectionEntries, observer) {
    var onDemandDeferExecuteActionTriggered = false,
        deferId = null;
    intersectionEntries.forEach(function (entry) {
      if (entry.intersectionRatio > 0) {
        onDemandDeferExecuteActionTriggered = true;
        deferId = entry.target.getAttribute(internal.constants.deferIdAttribute);
      }
    });

    if (onDemandDeferExecuteActionTriggered === true) {
      observer.disconnect();
      internal.executeOnDemandDeferredComponent(deferId);
    }
  };

  internal.logMessage = function (message, forceLog) {
    if (external.debugMode || forceLog) {
      console.log("[ComponentRegistrar] " + message);
    }
  };

  internal.setStatus = function (status) {
    internal.logMessage("Status: " + status);
    window.componentRegistrarStatus = status; //global status available to third parties (maxymiser, etc)
  };

  internal.logExecutionStatus = function () {
    var remainingComponents = internal.getFilteredComponents(null, false);

    if (remainingComponents.length === 0) {
      internal.setStatus(internal.constants.status.allComponentsComplete);
    }
  };

  internal.createSimpleIntersectionObserver = function () {
    //IntersectionObserver is used to determine if the user has started scrolling down the page.
    //After the threshold has been met, deferred components will be executed.
    if (!isIntersectionObserverSupported()) {
      internal.logMessage("IntersectionObserver is not available in this browser");
      return;
    }

    var observer = new window.IntersectionObserver(internal.handleSimpleIntersectionEvent, internal.config.simpleObserverOptions);
    var target = getDefaultObserverTarget(); //listen for visibility changes of target element

    observer.observe(target);
  };

  internal.createOnDemandIntersectionObserverForTarget = function (onDemandDomTarget) {
    //attribute value ties a DOM element to a component instance
    var deferId = onDemandDomTarget.getAttribute(internal.constants.deferIdAttribute);
    var onDemandComponent = internal.getOnDemandDeferredComponent(deferId); //only observe on targets that have a corresponding deferred component

    if (onDemandComponent) {
      var onDemandObserver = new window.IntersectionObserver(internal.handleOnDemandIntersectionEvent, internal.config.onDemandObserverOptions);
      onDemandObserver.observe(onDemandDomTarget);
    }
  };

  internal.createOnDemandIntersectionObservers = function () {
    //IntersectionObserver is used to determine if the user has started scrolling down the page.
    //After the threshold has been met, deferred components will be executed.
    if (!isIntersectionObserverSupported()) {
      internal.logMessage("IntersectionObserver is not available in this browser");
      return;
    }

    var onDemandAttributeSelector = "[" + internal.constants.deferIdAttribute + "]";
    var onDemandDomTargets = document.querySelectorAll(onDemandAttributeSelector); //find all with onDemand id attribute
    //create an observer and listen for visibility changes on each onDemand target element

    for (var i = 0; i < onDemandDomTargets.length; i++) {
      internal.createOnDemandIntersectionObserverForTarget(onDemandDomTargets[i]);
    }
  };

  internal.onDOMContentLoaded = function () {};

  internal.init = function () {
    //PS Customization: move these changes back into Common
    external.debugMode = !!window.debugComponentRegistrar || !!window.location.search.match(/debugComponentRegistrar=true/i);
    external.componentDeferralDisabled = !!window.disableComponentDefer || !!window.location.search.match(/disableComponentDefer=true/i) || !!GeneralMills.getGlobalSetting("isSearchEngineRequest");

    if (internal.config.testMode === true) {
      external.internal = internal;
    }
  };

  external.initialize = function () {
    internal.domContentLoaded = true;
    internal.executeImmediateComponents(); //execute all non-deferred components

    internal.createSimpleIntersectionObserver(); //set up single observer for simple deferred components

    internal.createOnDemandIntersectionObservers(); //set up observer for each onDemand deferred component

    document.addEventListener("DOMContentLoaded", internal.onDOMContentLoaded);
    internal.setStatus(internal.constants.status.initializeComplete);
    internal.onDOMContentLoaded();
  };

  external.registerComponent = function (func, componentMetadata) {
    internal.logMessage("Component registration requested: " + JSON.stringify(componentMetadata));

    if (typeof func !== "function") {
      internal.logMessage("Unable to register component without valid func.", true);
      return;
    }

    if (!componentMetadata || !componentMetadata.componentName) {
      internal.logMessage("Incomplete componentMetadata provided - componentName is required: " + JSON.stringify(componentMetadata), true);
      return;
    }

    var componentEntry = {
      func: func,
      componentMetadata: componentMetadata
    };

    if (internal.domContentLoaded === true) {
      internal.logMessage("DOMContentLoaded fired prior to component registration. Executing component.");
      internal.executeComponent(componentEntry);
    } else {
      internal.prepareComponent(componentEntry);
    }
  }; //init during object constructrion
  //internal.initialize();


  internal.init();
  return external;
};

var cr = new componentRegistrar();
export default cr;