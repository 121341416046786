import { MULTI_CHOICE_QUESTION_TYPE } from '../_constants';
import { surveyStepEvent } from '../_util';
import { cookie } from '@Shared/Common';
var state = {
  config: null,
  registrationConfig: null,
  staticQuestions: [],
  currentStepNumber: 0,
  steps: [],
  errorMessage: '',
  currentValidationClass: ''
};
var getters = {
  config: function config(state) {
    return state.config;
  },
  registrationConfig: function registrationConfig(state) {
    return state.registrationConfig;
  },
  staticQuestions: function staticQuestions(state) {
    return state.staticQuestions;
  },
  steps: function steps(state) {
    return state.steps;
  },
  questionSteps: function questionSteps(state) {
    return state.steps.filter(function (s) {
      return s.question;
    });
  },
  currentStepNumber: function currentStepNumber(state) {
    return state.currentStepNumber;
  },
  stepCount: function stepCount(state) {
    return state.steps.length;
  },
  currentStep: function currentStep(state) {
    return state.steps[state.currentStepNumber];
  },
  currentQuestion: function currentQuestion(state, getters) {
    return getters.currentStep && getters.currentStep.question;
  },
  currentQuestionId: function currentQuestionId(state, getters) {
    return getters.currentQuestion && getters.currentQuestion.id;
  },
  currentAnswer: function currentAnswer(state, getters) {
    return getters.currentQuestion && getters.currentQuestion.answers;
  },
  currentValidationClass: function currentValidationClass(state) {
    return state.currentValidationClass;
  },
  errorMessage: function errorMessage(state) {
    return state.errorMessage;
  },
  answers: function answers(state, getters) {
    return getters.questionSteps.reduce(function (answers, step) {
      var answer = {};
      answer[step.question.id] = step.question.answers;
      return Object.assign(answers, answer);
    }, {});
  },
  isCurrentAnswerValid: function isCurrentAnswerValid(state, getters) {
    return !getters.currentAnswer || !!(getters.currentAnswer && getters.currentAnswer.isValid);
  },
  isIntroductionStep: function isIntroductionStep(state, getters) {
    return !!(getters.currentStep && getters.currentStep.isIntroductionStep);
  },
  isRegistrationStep: function isRegistrationStep(state, getters) {
    return !!(getters.currentStep && getters.currentStep.isRegistrationStep);
  },
  isAuthenticatedUser: function isAuthenticatedUser(state) {
    return !!(state.config && state.config.isAuthenticatedUser);
  },
  isInModal: function isInModal(state) {
    return !!(state.config && state.config.isInModal);
  },
  genericErrorMessage: function genericErrorMessage(state) {
    return state.config && state.config.genericErrorMessage;
  },
  progressBarComponent: function progressBarComponent(state, getters) {
    return getters.currentQuestion ? 'progressBar' : '';
  }
};
var mutations = {
  set_config: function set_config(state, val) {
    state.config = JSON.parse(JSON.stringify(val));
  },
  set_registration_config: function set_registration_config(state, val) {
    state.registrationConfig = JSON.parse(JSON.stringify(val));
  },
  set_static_questions: function set_static_questions(state, val) {
    state.staticQuestions = val.slice();
  },
  set_current_step_number: function set_current_step_number(state, val) {
    state.currentStepNumber = val;
  },
  set_steps: function set_steps(state, val) {
    state.steps = val.slice();
  },
  set_error_message: function set_error_message(state, val) {
    state.errorMessage = val;
  },
  set_current_validation_class: function set_current_validation_class(state, val) {
    state.currentValidationClass = val;
  },
  set_answers: function set_answers(state, val) {
    var answersCopy = JSON.parse(JSON.stringify(val));
    state.steps.forEach(function (s) {
      if (s.question) {
        s.question.answers = answersCopy[s.question.id];
      }
    });
  },
  set_current_question_choices: function set_current_question_choices(state, val) {
    state.steps[state.currentStepNumber].question.choices = val.slice();
  },
  remove_current_question_choice: function remove_current_question_choice(state, index) {
    state.steps[state.currentStepNumber].question.choices.splice(index, 1);
  },
  remove_current_question_answer: function remove_current_question_answer(state, index) {
    state.steps[state.currentStepNumber].question.answers.value.splice(index, 1);
  }
};
export var actions = {
  setConfig: function setConfig(_ref, config) {
    var commit = _ref.commit;
    commit('set_config', config);
  },
  setRegistrationConfig: function setRegistrationConfig(_ref2, config) {
    var commit = _ref2.commit;
    commit('set_registration_config', config);
  },
  setStaticQuestions: function setStaticQuestions(_ref3, questions) {
    var commit = _ref3.commit;
    commit('set_static_questions', questions);
  },
  setCurrentStepNumber: function setCurrentStepNumber(_ref4, newStepNumber) {
    var commit = _ref4.commit,
        getters = _ref4.getters,
        dispatch = _ref4.dispatch;

    if (newStepNumber >= 0 && newStepNumber < getters.stepCount) {
      if (newStepNumber > getters.currentStepNumber && !getters.isCurrentAnswerValid) {
        // do not go forward if current answer is invalid
        dispatch('setAnswerErrorMessage');
      } else {
        // clear error message
        commit('set_error_message', ''); // trigger analytics event only if stepping forward

        if (newStepNumber > getters.currentStepNumber) {
          surveyStepEvent(getters.currentStepNumber, getters.stepCount, getters.isAuthenticatedUser);
        }

        if (getters.isIntroductionStep || getters.isRegistrationStep) {
          commit('set_current_step_number', newStepNumber);
        } else {
          // Save answers after each page
          dispatch('saveAnswersToCookie');
          commit('set_current_step_number', newStepNumber);
          dispatch('setValidationClass');
          dispatch('removeInvalidChoices');
        }
      }
    }
  },
  setSteps: function setSteps(_ref5, steps) {
    var commit = _ref5.commit;
    commit('set_steps', steps);
  },
  setErrorMessage: function setErrorMessage(_ref6, message) {
    var commit = _ref6.commit;
    commit('set_error_message', message);
  },
  setAnswerErrorMessage: function setAnswerErrorMessage(_ref7) {
    var commit = _ref7.commit,
        getters = _ref7.getters;

    if (getters.currentQuestion.questionType === MULTI_CHOICE_QUESTION_TYPE) {
      if (getters.currentAnswer.value.length < getters.currentQuestion.minimum) {
        commit('set_error_message', getters.currentQuestion.minimumErrorMessage);
      }
    } else {
      commit('set_error_message', getters.genericErrorMessage);
    }
  },
  setValidationClass: function setValidationClass(_ref8) {
    var commit = _ref8.commit,
        getters = _ref8.getters;

    if (getters.currentQuestion && getters.currentQuestion.questionType === MULTI_CHOICE_QUESTION_TYPE) {
      // Only show valid checkmark if there is a minimum and it's met
      if (getters.currentQuestion.minimum > 0) {
        var validationClass = getters.currentAnswer.value.length >= getters.currentQuestion.minimum ? 'valid' : '';
        commit('set_current_validation_class', validationClass);
      } else {
        commit('set_current_validation_class', '');
      }
    }
  },
  setAnswers: function setAnswers(_ref9, answers) {
    var commit = _ref9.commit;
    commit('set_answers', answers);
  },
  saveAnswersToCookie: function saveAnswersToCookie(_ref10) {
    var commit = _ref10.commit,
        getters = _ref10.getters;
    var questionsToBeSaved = {
      questions: getters.questionSteps.map(function (q) {
        var questionAnswers = {
          profileQuestionId: q.question.profileQuestionId,
          questionType: q.question.questionType
        };

        if (q.question.questionType === MULTI_CHOICE_QUESTION_TYPE) {
          questionAnswers.answers = q.question.answers.value;
        } else questionAnswers.answer = q.question.answers.value;

        return questionAnswers;
      })
    };
    cookie.setCookie("gmi.survey.questions", JSON.stringify(questionsToBeSaved), null, true);
    if (getters.isAuthenticatedUser && getters.currentStepNumber == getters.stepCount - 1 || !getters.isAuthenticatedUser && getters.currentStepNumber == getters.stepCount - 2) cookie.setCookie("gmi.survey.completed", "1", null, true);
  },
  removeInvalidChoices: function removeInvalidChoices(_ref11) {
    var commit = _ref11.commit,
        getters = _ref11.getters;
    // wait for currentQuestion to update
    setTimeout(function () {
      if (getters.currentQuestion && getters.currentQuestion.questionType === MULTI_CHOICE_QUESTION_TYPE) {
        if (getters.currentQuestion.removeChoicesFromQuestion.length > 0) {
          // Reset choices for currentQuestion from original config
          var originalQuestion = getters.staticQuestions.find(function (q) {
            return q.profileQuestionId === getters.currentQuestion.id;
          });
          commit('set_current_question_choices', originalQuestion.choices); // Loop through list of questions who's answers should be removed from currentQuestion

          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = getters.currentQuestion.removeChoicesFromQuestion[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var questionId = _step.value;
              // Find question and answers
              var foundQuestionStep = getters.questionSteps.find(function (s) {
                return s.question.id === questionId;
              });
              var foundQuestionAnswers = getters.answers[foundQuestionStep.question.id]; // Construct array of text for answers

              var answerTexts = foundQuestionStep.question.choices.filter(function (choice) {
                return foundQuestionAnswers.value.find(function (a) {
                  return a === choice.key;
                });
              }).map(function (a) {
                return a.value;
              });
              var _iteratorNormalCompletion2 = true;
              var _didIteratorError2 = false;
              var _iteratorError2 = undefined;

              try {
                for (var _iterator2 = answerTexts[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                  var text = _step2.value;
                  // Remove any matching choices
                  var matchingChoiceIndex = getters.currentQuestion.choices.findIndex(function (c) {
                    return c.value === text;
                  });
                  var matchingChoice = getters.currentQuestion.choices.find(function (c) {
                    return c.value === text;
                  });

                  if (matchingChoiceIndex > -1) {
                    commit('remove_current_question_choice', matchingChoiceIndex); // Remove from currentQuestion answers if the choice was also selected

                    var matchingAnswerIndex = getters.currentQuestion.answers.value.findIndex(function (a) {
                      return a === matchingChoice.key;
                    });

                    if (matchingAnswerIndex > -1) {
                      commit('remove_current_question_answer', matchingAnswerIndex);
                    }
                  }
                }
              } catch (err) {
                _didIteratorError2 = true;
                _iteratorError2 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                    _iterator2.return();
                  }
                } finally {
                  if (_didIteratorError2) {
                    throw _iteratorError2;
                  }
                }
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        }
      }
    }, 1);
  }
};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};