import jwtDecode from "jwt-decode";
export var handleRedirect = function handleRedirect(responseUrl, defaultUrl) {
  var urlParams = new URLSearchParams(window.location.search);

  var getDecodedParam = function getDecodedParam(key) {
    var param = urlParams.get(key);
    return param ? decodeURIComponent(param) : null;
  };

  var refUrl = getDecodedParam("RefUrl");
  var returnUrl = getDecodedParam("returnUrl");
  var redirectUrl = responseUrl || refUrl || returnUrl || defaultUrl; //Determine if redirectUrl is attempting javascript XSS or directing to another domain

  try {
    var url = new URL(redirectUrl, window.location.origin);

    if (redirectUrl.toLowerCase().startsWith("javascript:") || redirectUrl.toLowerCase().startsWith("data:") || redirectUrl.toLowerCase().startsWith("vbscript:") || url.hostname !== window.location.hostname || redirectUrl === "/404") {
      redirectUrl = "/"; // default to home page
    }
  } catch (e) {
    // Handle invalid URL (e.g., malformed URL string)
    console.error("Invalid URL:", redirectUrl, e);
  }

  setTimeout(function () {
    // This timer gives the unica tag a chance to complete before redirecting the request
    window.location.assign(redirectUrl);
  }, 1000);
};
export var testRegex = function testRegex(expression, value) {
  var regex;

  try {
    regex = new RegExp(expression);
    return regex.test(value);
  } catch (_unused) {
    return false;
  }
};
export var parseJwtToken = function parseJwtToken(token) {
  return jwtDecode(token);
};
export var newsletterOptInEvent = function newsletterOptInEvent(listName, listId, SubsPosition) {
  if (listName && listId) {
    GeneralMills.Analytics.raiseEvent({
      EventType: "NewsletterOptIn",
      NewsletterTitle: listName,
      NewsletterId: listId,
      SubscriberPosition: SubsPosition
    });
  }
};
export var surveyStepEvent = function surveyStepEvent(currentStepNumber, stepCount, isAuthenticatedUser) {
  if (currentStepNumber !== null && stepCount !== null && isAuthenticatedUser !== null) {
    if (currentStepNumber < 1) {
      var surveyEvent = {
        EventType: "SurveyStart"
      };
    } else if (currentStepNumber === stepCount - 1 && isAuthenticatedUser || currentStepNumber == stepCount - 2 && !isAuthenticatedUser) {
      var surveyEvent = {
        EventType: "SurveyComplete"
      };
    } else {
      var surveyEvent = {
        EventType: "SurveyStep_" + currentStepNumber
      };
    }

    GeneralMills.Analytics.raiseEvent(surveyEvent, {
      virtualPageView: false
    });
  }
};