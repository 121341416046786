import { cookie } from '@Shared/Common';
export default {
  pageVisited: function pageVisited() {
    var cookieName = "PageCount";

    if (GeneralMills && GeneralMills.getGlobalSetting && GeneralMills.getGlobalSetting('userPrivacy_FunctionalEnabled') === false) {
      cookie.removeCookie(cookieName);
      return;
    }

    var cookieValue = cookie.getCookie(cookieName);
    var pageCount = 0;
    if (cookieValue) pageCount = parseInt(cookieValue);
    cookie.setCookie(cookieName, pageCount + 1);
  }
};