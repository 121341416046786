import _objectSpread from "@babel/runtime/helpers/objectSpread";
import componentRegistrar from '../modules/_componentRegistrar';
import vueLoader from '../modules/_vueLoader';
import 'core-js/fn/promise';
import 'cssrelpreload';
import page from '../modules/_page';

(function () {
  if (!window.fetch) {
    import(
    /* webpackChunkName: "polyfill-fetch" */
    'whatwg-fetch');
  }

  if (!window.URLSearchParams) {
    import(
    /* webpackChunkName: "polyfill-urlsearchparams" */
    'url-search-params-polyfill');
  }

  window.GeneralMills = window.GeneralMills || {};
  GeneralMills.PandoSites = GeneralMills.PandoSites || {};
  GeneralMills.PandoSites.Modules = GeneralMills.PandoSites.Modules || {};
  GeneralMills.PandoSites.NewsletterCta = {};
  GeneralMills.PandoSites.NewsletterCta.Interstitial = {};

  GeneralMills.PandoSites.RegisterControlInstance = function (name, config, componentMetadata, isVueComponent) {
    var elementId = componentMetadata.deferOptions.deferId;

    var loadVueComponent = function loadVueComponent() {
      vueLoader.load(GeneralMills.PandoSites.Modules, name, elementId, config);
    };

    var loadJsComponent = function loadJsComponent() {
      var returnValue = GeneralMills.PandoSites.Modules[name](config, elementId);

      if (returnValue instanceof Promise) {
        // if the module is dynamically loaded, initialize the component after the promise is resolved
        returnValue.then(function (module) {
          module.default(config, elementId);
        }).error(function () {
          GeneralMills.debugMode && console.log('Error loading module ' + name);
        });
      }
    };

    var initialFunction = isVueComponent ? loadVueComponent : loadJsComponent;
    componentRegistrar.registerComponent(initialFunction, componentMetadata);
  };

  GeneralMills.PandoSites.RegisterModules = function (modules) {
    GeneralMills.PandoSites.Modules = _objectSpread({}, modules, GeneralMills.PandoSites.Modules);
  };

  document.addEventListener("DOMContentLoaded", function () {
    componentRegistrar.initialize();
  });
  page.pageVisited();
})();